.cc-reviewform-cnt{
    width: 95%;
    max-width: 1400px;
    margin: auto;
    padding-bottom: 50px;
}

.cc-card{
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 1px 24px #00000003;
    opacity: 1;
    position: relative;
    margin: 10px 0px 0px 0px;
    animation-timing-function: ease-out;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #dbd9d9;
    border-bottom: unset;
    min-height: 225px;
}

.cc-card-heading{
    padding: 15px 15px 5px 15px;
    text-align: left;
    font: 20px/22px Rubik;
    font-weight: 600;
    letter-spacing: 0;
    color: #4B4F54;
    opacity: 0.9;
    text-transform: capitalize;
}

.cc-card-body{
    padding: 0 15px;
}

.cc-card-body label{
    font-weight: 600;
    opacity: 0.8;
}

.cc-card-body .cc-card-info{
    margin:0px;
    padding:0px;
    /* flex-basis: 60%; */
    align-self: flex-end;
    overflow-wrap: anywhere;
    word-break: break-all;
    opacity: 0.8;
    text-transform: capitalize;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cc-card-text{
    text-align: left;
    font: 14px/26px Rubik;
    letter-spacing: 0;
    color: #4B4F54;
    
    white-space: nowrap;
}

.cc-border{
    /* background-color: #C7579A; */
    height: 8px;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    opacity: 1;
}

.href{
    color:#3EB1C8;
}

.href:hover{
    color:#3EB1C8;
}

.cc-approval-btns-cnt{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.cc-admin-btns-cnt{
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.doc-comment-cnt{
    display: flex;
}

.supporting-doc-list-cnt .supportingDocTable, .supporting-doc-line{
    width: 90%;
}

.supporting-doc-list-cnt{
    flex-basis: 60%;
    margin-top: 30px;
}

.cmt-history-cnt{
    flex-basis: 40%;
    margin-top: 30px;
}

.stage-parent{
    width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;
}

.stage-cnt{
    overflow-x: scroll;
}

.cc-roadmap-note{
    position: absolute;
    top: -13px;
    left: 77px;
    color: #774def;
    font-size: 20px;
}


/* mobile  */
@media (max-width:767px){
    .cc-approval-btns-cnt{
        width: 100%;
        flex-direction: column;
    }

    .cc-approval-btns-cnt button{
        margin-top: 15px;
    }

    .doc-comment-cnt{
        flex-direction: column;
    }

    .supporting-doc-list-cnt .supportingDocTable, .supporting-doc-line{
        width: 100%;
    }

    .supporting-doc-list-cnt, .cmt-history-cnt{
        flex-basis: 100%;
    }

    .stage-cnt{
        width: 100%;
    }
}


/* tablet  */
@media (min-width:768px) and (max-width:1199px){
    .cc-approval-btns-cnt{
        width: 100%;
    }
}