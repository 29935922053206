body {
    margin: 0;
    font-family: 'Rubik';    
    color: #34383c;
}

.heading-1{
    padding: 15px 15px 5px 0;
    text-align: left;
    font: 20px/22px Rubik;
    font-weight: 600;
    letter-spacing: 0;
    color: #774def;
    /* color: #4B4F54; */
    opacity: 0.9;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.cc-horizontal-line{
    /* background: #C7579A; */
    margin-bottom: 15px;
    margin-top: 0;
}

.ant-form-item{
    margin-bottom: 10px;
}

.cc-icons{
    color: #774def;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.8;
}

.cc-info-icon{
    color: #774def;
    font-size: 16px;
    cursor: pointer;
}

.cc-icons-grey{
    color: #34383c !important;
}



.ortho-btn, .modal-btn, .modal-btn:active, .modal-btn:focus{
    cursor: pointer;
    text-decoration: none;
    border: 0;
    background: #774def 0 0 no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    letter-spacing: 0;
    color: #fff;
    opacity: 1;
    margin: 0;
    padding: 8px 15px;
    transition: .15s;
    transition-timing-function: ease-out;
}

.ortho-btn:hover, .modal-btn:hover{
    border: 0;
    background-color: #3e318f;
    color:#fff !important;
}

.ortho-btn:active, .ortho-btn:focus{
    border: 0;
    color:#fff !important;
    background-color: #3e318f;
    background: #3e318f !important;
}

.info-modal{
    width: 50% !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before{
    margin-right: 2px !important;
}

.ant-empty-normal{
    margin: 0 !important;
}

.ant-input-number-handler-wrap{
    display: none !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #774def !important;
    border-color: #774def !important;
}

.ant-select-dropdown{
    width: fit-content !important;
}

.ant-form-item-extra {
    color: #774def  !important;
    opacity: 0.8;
}

.message-style{
    display: flex;
    justify-content: flex-end;
    margin-top:50px;
}

.message-style .ant-message-notice-content{
    background: #fde8e8;
    display: flex;
    width: 400px;
    justify-content: flex-start;
    align-items: center;
    min-height: 60px;
}

.header-cnt-outer{
    background: #774def;   
}

.header-cnt-inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: auto;
    max-width: 1400px;
    padding-top: 3px;
}

.logo{
    width: 285px;
    height: 30px;
}

.app-name{
    font-size: 40px;
    width: 285px;
    color: white;
}

.header-options{
    width: 285px;
    display: flex;
    justify-content: flex-end;
    color: white;

}

.signout{
    font-size: 17px;
    cursor: pointer;
}

.ant-input[disabled], .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    color: #000000bf !important;
}

.cc-link{
    color: #774def !important;
    cursor: pointer !important;
}

.cc-link:hover{
    text-decoration: underline !important;
}

.cc-note{
    opacity: 0.5 !important;
}

.ant-dropdown-menu{
    border-radius: 5px;
}

.cc-user-icon{
    font-size: 18px;
    margin-left: 20px;
    cursor:pointer;
}

.request-form .ant-spin-text{
    font-size: 20px !important;
    background: white !important;
}


.cc-stage{
    margin: 20px auto 0px;
    padding-bottom: 10px;
    /* justify-content: center; */
}

.cc-stage .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #789D4A;
}

.cc-stage .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #789D4A;
}

.cc-stage .ant-steps-finish-icon svg{
    margin-bottom: 7px;
    color:#789D4A;
}

.cc-stage .ant-steps-error-icon svg{
    margin-bottom: 7px;
}

/* .ant-steps-item-title{
    font-size: 16px !important;
} */

.cc-stage .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    color:#789D4A;
}

.cc-stage .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background: #F2A900;
}

.cc-stage .ant-steps-item-process .ant-steps-item-icon{
border: #F2A900;
}

.cc-stage .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    color: #F2A900;
}

.ant-steps-label-vertical .ant-steps-item-content{
    width: 132px !important;
    margin-right: 10px;
    cursor: default !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description{
    color: #F2A900 !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description{
    color: #774def;
    opacity: 0.8;
}

.ant-steps-item-description p{
    margin: 0 !important;
}

.ant-steps-item-description{
    width: 124px !important;
}

.ant-steps-label-vertical .ant-steps-item-tail{
    margin-left: 60px !important;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon{
    margin-left: 52px !important;
}

.ant-steps-item{
    flex: unset !important;
    width: 150px !important;
}

/* scroll bar css  */
.stage-cnt::-webkit-scrollbar {
    height: 8px;
}

.stage-cnt::-webkit-scrollbar-thumb {
    background-color: #80808021;
    border-radius: 10px;
}


/* tooltip css  */
.ant-tooltip-inner {
    color: #774def !important;
    background-color: #fff !important;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    border-bottom-color: #fff !important;
}

.ant-tooltip-arrow::before{
    background-color: #fff !important;
}

.ant-tooltip-arrow-content{
    background-color: #fff !important;
    --antd-arrow-background-color: #fff !important;
}

.ant-progress-bg {
    height: 20px !important;
}

.ant-progress-status-normal .ant-progress-bg{
    background-color: #774def !important;
}

.cc-env-detector{
    position: absolute;
    left: calc(50% - 50px);
    color: white;
    background: #6cda6cf2;
    padding: 0px 10px;
    font-weight: 600;
    top: 0;
    width: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0 0 10px 10px;
    text-align: center;
    line-height: 17px;
    font-size: 14px;
}

/* Mobile  */
@media (max-width:767px){
    .info-modal{
        width: 90% !important;
    }

    .app-name, .header-options{
        width: fit-content;
    }
    
    .app-name{
        font-size: 30px;
    }

    .logo{
        display: none;
    }

    .ant-steps-item, .ant-steps-item-description{
        width: 100% !important;
    }
}