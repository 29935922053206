
#p-table{
    margin-left: 0;
  }

.reportDropdown, .navDropdown{
    background: white !important;
    margin: 10px 0;
}


.report .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: white !important;
}

.report .ant-menu-item{
    height: fit-content !important;
    cursor: default !important;
    color:#272727 !important;
}

.report .ant-checkbox-group{
    display: flex;
    flex-direction: column;
}

.report .ant-checkbox-group-item {
    margin-bottom: 8px;
}

.report .ant-menu-light .ant-menu-item:hover{
    color:#272727 !important;
}

.report .ant-select-selection-placeholder{
    color:#272727;
}

.report .left{
    width:36%;
}

.report .actionBtn{
    margin:10px 0px;
}

.filterContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0.5% 1.5%;
    max-width: 1400px;
}




.filterBtns{
    margin: 5px 12px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
}

#report-table{
    overflow-x: scroll;
    margin: 0 2.5%;
}

.report input::placeholder{
    color:#272727;
  }

.report .actionBtn{
    color: white;
    border-color: #774def !important;
    background: #774def !important;
}
.report .actionBtn:hover{
    border-color: #774def !important;
    background: #774def !important;
}



.report .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff !important;
    border-color: #1890ff !important;
}

.report .ant-checkbox-checked::after {
    border: 1px solid #1890ff;
}

.report .ant-checkbox-wrapper-checked span:nth-child(2){
    font-weight: 400;
}

th.ant-table-cell.productColumnClass{
    padding-left:16px !important;
}

.panel-mobile{
    border-bottom:none !important;
    width:fit-content;
}

.panel{
    background: white;
    border-bottom:none !important;
    border:none;
    width:fit-content;
    border-radius: 20px !important;
    margin: auto;
}

.panel .ant-collapse-header{
    padding: 5px !important;
    justify-content: center;
    
}

.panel .ant-collapse-content-box{
    padding:0px !important;
}

.report .ant-table-thead > tr > th, .report .ant-table-tbody > tr > td, .report .ant-table tfoot > tr > th, .report .ant-table tfoot > tr > td{
    padding:7px 3px !important;
}

.productColumnClass{
    width:5%;
}

.report .ant-table {
    font-size: 12px !important;
}

.report .ant-table .ant-table-column-title {
   font-size: 16px !important;
   font-weight: 500;
}
.report #p-table.ant-table {
    font-size: 11px !important;
}

.report td.DMemail {
    overflow-wrap: anywhere !important;
}
.report td.ORemail {
    overflow-wrap: anywhere !important;
}
.report td.status {
    overflow-wrap: anywhere !important;
}

.report .ant-pagination{
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.filterBtn{
    width: 200px; 
    margin: 10px 0;
}

.statusBtn{
    padding:5px;
}

.statusBtn span{
    width:200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.reqDateBtn {
    padding:5px;
    width:200px;
}


.mobileNav{
    display: none;
}
.mobileHeading{
    font-weight: 700;
}

.mobileTable tr:nth-child(even) {
    background-color: #f1f1f1;
}

.report .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}

.navbarContainer{
    
    color: white;
    
    background: #cf2f44;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-left: 0px;
}

.navCenter{
    font-size: 28px;
    font-family: Calluna;
}

.navLeft{
    font-size: 18px;
    cursor: pointer;
}

.navLeft-mobile{
    font-size: 18px;
}

.leftNavContainer{
    width: 15%;
}

.navbarRight{
    display: flex;
    justify-content: flex-end;
    width: 18%;
}

.navbarItem{
    margin: 10px;
    cursor: pointer;
}

.navbarItem:hover{
    text-decoration: underline;
}

.ant-dropdown{
    z-index: 20001;
}

.customize
.requestDateLabel{
    margin-right:10px;
}

.alignLeft {
    text-align:left;
    
}

.floatLeft {
    float: left;
}

.adjustMargin {
    margin-left: 40px;
   
}

.adjustWidth{
    width:25% !important;
}

.customizeMargin{
    margin-left: 300px;
    width:25% !important;
   
}

.alignRequestDate {
    margin-left: 0;
    padding-left: 10px !important;
}

.radioFontAdjust {
    font-size: 14px;
    margin-right: 6px;    
}

.customerAccountText {
    width: 400px;
}

.verticalHeight {
    margin-bottom: 20px;
}

.alignHeight {
    padding-top: 13px;
}

.reportTextArea{
height: 70%;
margin-top: 2%;
margin-bottom: 4%;
width:350px;

}


.reportRadio input{
    margin-right: 3px;
}

.columnScroll{
    max-height: 250px;
      overflow-x: scroll;
  }

.customizeColumn{
    margin: 0 25px 0 5px;
    top: -8px;
}
.dateRangeAdjust{
    margin-left: 158px;
}

.resultsLabel{
    align-items: center;
}

.exportReport{
    margin-left: 20px;
}

.request-heading{
    margin: 3px;
    font-size: 18px;
    font-family: Rubik;
    font-weight: 600;
    display: inline-block;
    color: #4b4f54;
}

.customDateAdjust{
    float: left;
    margin-left: 8px;
}

.checkboxAdjust input{
    display: block;
    width: 100%;
}
.amt-col{
    text-align: right;

}


@media (max-width:767px){
    .navbarContainer{
        flex-direction: column;
    }

    .navbarRight{
        width: 100%;
        justify-content: space-between;
        padding-left: 10px;
    }

    .navbarItem{
        margin-left: 0;
    }

    .lobRadio{
        display: flex;
        flex-direction: column;
      
    }

    .dateRangeAdjust{
        margin-left: 124px !important;
    }

    .requestDateLabel{
        flex-direction: column;
        display: flex;
        margin-left: 20px;
    }

    .textareaControlAdjust{
        width: 53% !important;
    }

    .requestCustomDate{
        flex-direction: column;
        margin-left: 170px;
    }

    .statusContainer{
        flex-direction: column;
        margin-left: 180px;
        display: flex;
    }

    .dateMobileAdjust{
        width:35% !important;
        margin-left: -5px;
    }

    .lastWeekAdjust{
        margin-left: 2px;
    }

    .lastYearAdjust{
        margin-left: -5px;
    }
   

    .lastMonthAdjust{
        margin-left: 5px;
    }
    .customMobileAdjust{
        margin-left: 10px;
    }

    .typeMobileAdjust{
        width:45% !important;
        margin-left: 10px;
    }

    .statusMobileAdjust{
        width:40% !important;
        margin-left:10px;
        
    }

    .lobMobileAdjust{
        width:25% !important;
        margin-left: -10px;
    }

    .customerAcct{
        text-align: left;
    }

    .labelAdjust{
        margin-left: 15px;
        font-size: 14px;
        font-family: Rubik;
        color: #4b4f54;
        width: 40% !important;
    }

    .customizeMargin{
        margin-left: 40px;
        margin-top: 30px;
    }

 
    .amt-col{
        text-align: right;
    
    }

}

@media (min-width:1400px) {
    .report .ant-table {
        font-size: 14px !important;
    }
    .filterContainer{
        max-width: 1400px;
        
    }
   
}


