.ant-steps-item-skipped .ant-steps-item-icon {
    background-color: #eeeaea;
}

.ant-steps-item-skipped .ant-steps-item-icon {
    font-size: 0;
}

.ant-steps-item-skipped .ant-steps-item-icon::after {
    content: '\2197';
    font-size: 13px;
    text-align: center;
    color: grey;
}

.ant-steps-item-skipped .ant-steps-item-title {
    color: #999;
    /*Default color*/
}


.ant-steps-item-skipped>.ant-steps-item-container>.ant-steps-item-tail:after {
    background-color: #789D4A;
    /*overriding Default bg-color*/
}