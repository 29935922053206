


.cc-orderform-cnt{
    width: 95%;
    max-width: 1400px;
    margin: auto;
    padding: 50px 0;
}

.cc-req-date-id-cnt, .cc-receiving-cnt, .cc-sending-cnt, .cc-currency-exchangerate-cnt{
    display: flex;
}

.cc-input-field{
    width: 230px !important;
}

.cc-split-input-field{
    /* width: 30%; */
    margin-right: 50px;
}

.cc-textarea{
    width: 230px !important;
}

.cc-table-info-modal .ant-row{
    display: block !important;
}

.cc-table-info-modal .cc-modal-input-field{
    width: 80% !important;
    justify-content: space-between;
    /* padding-left: 0; */
}

.cc-modal-form-cnt{
    display: flex;
}

.cc-modal-form-child{
    flex-basis: 50%;
}

.cc-item-add-dlt-cnt{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.action-column{
    display: flex;
    justify-content: center;
}

.cc-add-icon, .cc-minus-icon{
    font-size: 20px;
}

.cc-add-icon{
    margin-right: 20px;
}


.arTable td, .apTable td, .supportingDocTable, .excelTable td{
    padding: 4px 4px !important;
    /* font-size: 16px; */
}

.arTable th, .apTable th, .supportingDocTable, .excelTable th{
    /* padding: 4px 4px !important; */
    /* font-size: 16px; */
    font-weight: 600 !important;
    opacity: 0.8;
}

.row-selection{
    width: 100px;
}

.supportingDoc-cnt{
    margin: 30px 50px 50px 0;
    flex-basis: 60%;
}

.cc-submit-btn-cnt{
    display: flex;
    justify-content: center;
}

.cc-table-info-modal .ant-form-item-label>label:after{
    display: none;
}

.supportingDoc-cnt .supportingDocTable{
    width: 100%;
}

.cc-doc-dlt-cnt{
    margin-top: 10px;
    width: 100%;
}

.supportingDocTable .ant-table th.ant-table-selection-column{
    width: 5% !important;
}

.supportingDocTable .doc-column{
    overflow-wrap: anywhere !important;
}

small{
    font-size: 95%;
    color: #774def;
}

.adjust-width-doc-cnt{
    flex-basis: 100%;
    margin-right: 0;
}

.adjust-width-doc-cnt .supportingDocTable, .adjust-width-doc-cnt .cc-doc-dlt-cnt{
    width: 50%;
}

.cc-request-fields-left{
    width: 45%;
}

.cc-request-fields-left-with-business{
    width: 63%;
}

.cc-business-place-cnt{
    display: flex;
}

.cc-input-business-field{
    width: 200px !important;
}

.cc-business-label{
    margin-left: 20px;
}

.cc-padding-left::before{
    content: unset !important;
}


.mobile-tooltip{
    display: none;
}

.dsktp-tooltip{
    display: inline-block;
}

/* sbb */
.info-modal-li {
    margin: 0 0 5px 0;
}

@media (min-width:1200px) and (max-width:1300px){
    .cc-request-fields-left-with-business{
        width: 68%;
    }
}

/* tablet  */
@media (min-width:768px) and (max-width:1199px){

    .cc-request-fields-cnt{
        display: flex;
    }

    small{
        font-size: 90%;
    }

    .cc-input-field, .cc-input-business-field{
        width: 200px !important;
    }

    .cc-request-fields-left{
        width: 55%;
    }

    .cc-business-place-cnt{
        flex-direction: column;
    }

    .cc-business-label{
        margin-left: 0px;
    }
}

/* Mobile  */
@media (max-width:767px){

    .cc-request-fields-cnt{
        display: flex;
        flex-direction: column;
    }

    .cc-req-date-id-cnt, .cc-receiving-cnt, .cc-sending-cnt, .cc-currency-exchangerate-cnt{
        flex-direction: column;
    }

    .cc-input-field, .cc-input-business-field{
        width: 100% !important;
    }

    .cc-split-input-field{
        margin-right: 0px;
    }

    .cc-textarea{
        width: unset !important;
    }

    .cc-modal-form-cnt{
        flex-direction: column;
    }

    .cc-table-info-modal .cc-modal-input-field{
        width: 100% !important;
    }

    .ant-form-item .ant-form-item-label {
        padding: 0 !important;
    }

    .supportingDocTable{
        width: 100%;
    }

    .adjust-width-doc-cnt .supportingDocTable, .adjust-width-doc-cnt .cc-doc-dlt-cnt{
        width: 100%;
    }
    
    
    .cc-doc-dlt-cnt{
        margin-top: 30px;
        width: 100%;
    }

    small{
        font-size: 90%;
    }

    .supportingDoc-cnt{
        margin: 30px 0 0 0;
    }

    .cc-request-fields-left{
        width: 100%;
    }

    .cc-business-place-cnt{
        flex-direction: column;
    }

    .cc-business-label{
        margin-left: 0px;
    }

    .mobile-tooltip{
        display: inline-block;
    }

    .dsktp-tooltip{
        display: none;
    }

    .cc-submit-btn-cnt{
        margin-top: 30px;
    }

    .cc-orderform-cnt{
        padding: 15px 0 30px 0;
    }

}