.cc-excel-upload-cnt{
    width: 50%;
    max-width: 1400px;
    margin: 30px auto;
}

.cc-upload-btn-cnt{
    display: flex;
    justify-content: space-between;
}

.cc-upload-btn{
    display: flex;
    align-items: center;
}

.file-name-span{
    margin-left: 15px;
}

.ant-progress-line{
    /* width: 50% !important; */
    margin-top: 20px;
}

.excel-success-row{
    color: #52c41a;
}

.excel-failed-row{
    color: red;
}

.excelTable{
    margin-top: 30px;
}

.disabled-btn, .disabled-btn:hover{
    color: rgba(0,0,0,.25) !important;
    background: #f5f5f5;
    cursor: not-allowed !important;
    text-decoration: none;
    pointer-events:none;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.25);
    opacity: 1;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0;
    padding: 8px 15px;
}

.excel-spin{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Tablet  */
@media (min-width:768px) and (max-width:1199px){
    .cc-excel-upload-cnt{
        width: 95%;
    }
}

/* Mobile  */
@media (max-width:767px){
    .cc-excel-upload-cnt{
        width: 95%;
    }

    .cc-upload-btn-cnt{
        flex-direction: column;
    }

    .cc-upload-btn{
        flex-direction: column;
        align-items: start;
    }

    .file-name-span{
        margin-top: 10px;
        margin-left: 0;
    }

    .prc-btn{
        margin-top: 10px;
        width: 100%;
    }

    .upld-btn{
        width: 100%;
    }
}