.dashboard-cnt, .arDashboard-cnt{
    width: 95%;
    margin: auto;
    max-width: 1400px;
}

.cc-new-request-dropdown{
    padding: 0 10px 0 5px;
}

.new-req-option{
    /* width: 155px; */
    width: max-content;
    font: 14px/26px Rubik;
    font-weight: 500;
    opacity: 0.9;
}

.new-req-option:hover, .new-req-option:hover .cc-icons{
    color: #774def !important;
    text-decoration: underline;
    cursor: pointer;
}

/* Transforming upload icon upside down to look like download icon */
.cc-icons-trans {
    transform: scaleY(-1);
}

.ant-dropdown-menu-item-disabled .new-req-option, .ant-dropdown-menu-item-disabled .new-req-option .cc-icons {
    color: rgba(0,0,0,.25) !important;
    cursor: not-allowed !important;
    text-decoration: none;
    pointer-events:none;
}

.optionBtn-mobile{
    position: absolute;
    top: 8px;
    right: 5px;
  }

.dashboardTable td, .arDashboardTable td{
    padding: 4px 4px !important;
}

.dashboardTable th, .arDashboardTable th{
    padding-left: 4px !important;
    font-weight: 600 !important;
    opacity: 0.8;
}

.dashboardTable .ant-table-thead>tr>th, .arDashboardTable .ant-table-thead>tr>th {
    
    background: white !important;
    border-bottom: unset !important;
}

.dashboardTable .ant-table-tbody>tr>td, .arDashboardTable .ant-table-tbody>tr>td {
    border-bottom: unset !important;
}

.cc-heading-icons-cnt{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboardTable-mobile .ant-table-tbody>tr:nth-of-type(2n), .arDashboardTable-mobile .ant-table-tbody>tr:nth-of-type(2n) {
    background-color: #f1f1f1;
}

.mobileDashboardTable tr{
    display: flex;
}

.mobileDashboardTable{
    margin-bottom: 20px;
}

.mobileHeading{
    font-weight: 600;
    opacity: 0.8;
    flex-basis: 40%;
}

.amt-col{
    text-align: right !important;
    padding-right: 20px !important;
}

@media (max-width:576px){
    .ant-pagination-options{
        display: unset !important;
    }
}

